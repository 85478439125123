





















































































import { Component, Vue } from "vue-property-decorator";
import { TimelineLite } from "gsap";

@Component
export default class OurQuality extends Vue {
  overlayDelay = false;

  mounted() {
    this.overlayDelay = false;
    this.overlayLoading = false;
    this.startAnimation(this.overlayDelay);
  }

  startAnimation(overlayDelay: boolean) {
    const image = this.$refs.image;
    const overlay = this.$refs.overlay;
    const title1 = this.$refs.title1;
    const content1 = this.$refs.content1;
    const title2 = this.$refs.title2;
    const content2 = this.$refs.content2;
    const timeline1 = new TimelineLite();
    const timeline2 = new TimelineLite();
    const initialDelay = overlayDelay ? 1.5 : 0;
    timeline1.from(overlay, {
      duration: 1.5,
      opacity: 0,
      ease: "power4",
      y: -50,
      delay: initialDelay
    });
    timeline2
      .from(image, {
        duration: 2.5,
        delay: 1 + initialDelay,
        opacity: 0,
        ease: "power4",
        y: -50
      })
      .to(image, { y: 0 });
    timeline1
      .from(title1, { duration: 1.5, opacity: 0, y: -50, ease: "power4" })
      .to(title1, { y: 0 });
    timeline2
      .from(
        content1,
        { duration: 1.25, opacity: 0, y: -50, ease: "power4" },
        "-=2"
      )
      .to(content1, { y: 0 });
    timeline1
      .from(
        title2,
        { duration: 1.5, opacity: 0, y: -50, ease: "power4" },
        "-=1"
      )
      .to(title2, { y: 0 });
    timeline2
      .from(
        content2,
        { duration: 1.25, opacity: 0, y: -50, ease: "power4" },
        "-=1.5"
      )
      .to(content2, { y: 0 });
  }

  get overlayLoading() {
    return this.$store.getters.overlayLoading;
  }
  set overlayLoading(value) {
    this.$store.commit("setOverlayLoading", value);
  }
}
